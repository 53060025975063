<template>
  <div class="content-provider-detail global-content-detail-all">
    <div class="global-content-detail" >
      <div id="iHeaderDetail" ref="iHeaderDetail">
        <header-detail-component-global :sTextDetail="
          this.sTabProviderOrPerson === 0
            ? 'Detalle de una empresa: '
            : 'Detalle de una persona física: '
        " sBackHref="Provider" />
        <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
      </div>
      <v-tabs v-model="tab" class="global-tabs tabs-provider-detail" :touchless="true" center-active
        background-color="transparent">
        <v-tab class="tab-provider-detail" v-for="(item, index) in tabsItems" :key="index">
          {{ item.tab }}
        </v-tab>
        <v-tabs-slider class="divider-tabs"></v-tabs-slider>
      </v-tabs>
      <v-tabs-items v-model="tab" class="global-item-tab">
        <!--#region INFORMACION GENERAL -->
        <v-tab-item>
          <!-- <provider-general-information-component :iTab="tab" /> -->
          <provider-general-information-global v-if="this.sTabProviderOrPerson === 0" :bAdminProvider="bAdminProvider"
            :iTab="tab" :idProvider="idProvider" />
          <person-general-information-global :bAdminProvider="bAdminProvider" v-if="this.sTabProviderOrPerson === 1" />
        </v-tab-item>
        <!--#endregion INFORMACION GENERAL -->

        <!--#region ORDENES DE COMPRA -->
        <v-tab-item>
          <o-p-component :iTab="tab" :idProvider="idProvider" />
        </v-tab-item>
        <!--#endregion ORDENES DE COMPRA -->

        <!--#region MATERIAS PRIMAS -->
        <v-tab-item>
          <raw-materials-detail-provider-component :iTab="tab" :idProvider="idProvider"
            :bAdminProvider="bAdminProvider" />
        </v-tab-item>
        <!--#endregion MATERIAS PRIMAS -->
      </v-tabs-items>


    </div>
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  name: "ProviderDetail",
  data() {
    return {
      idProvider: "",
      tab: 0,
      tabsItems: [
        { tab: "Información general" },
        { tab: "Operaciones" },
        { tab: "Materias primas" }
        // generalInformation: "Información general",
        // purchaseOrders: "Órdenes de compra",
        // rawMaterials: "Materias primas",
      ],
      iHeaderDetail: 0,
      screenHeight: 0,
      heightMax: 0,
      items: ["Empresa", "Persona física"],
      itemsBreadcrumbs: [
        {
          text: "Proveedores",
          disabled: false,
          to: { name: "Provider" },
        },
        {
          text: "Detalle de un ...",
          disabled: true,
          to: { name: "" },
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    this.$store.commit("setsNameDetail", "");
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.iHeaderDetail !== undefined) {
          this.heightMax = this.$refs.iHeaderDetail.clientHeight;
          this.heightMax = this.heightMax + 123; //123 IS NAVBAR
        }
        if (window.innerWidth > 599) {
          this.screenHeight = window.innerHeight - 100;
          // this.tabsItems[1].tab = "Órdenes de compra";
        } else {
          this.screenHeight = window.innerHeight - 100;
          // this.tabsItems[1].tab = "O. de compra";
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    setNameBreadcrumbs() {
      this.idProvider = this.$route.params.id;
      switch (this.sTabProviderOrPerson) {
        case 0:
          this.itemsBreadcrumbs[1].text = "Detalle de una empresa";
          break;
        case 1:
          this.itemsBreadcrumbs[1].text = "Detalle de una persona física";

          break;

        default:
          break;
      }
    },
  },
  computed: {
    sTabProviderOrPerson() {
      return this.$store.state.sTabPosition;
    },
    bShowProvider() {
      return this.$store.state.bShowProvider;
    },
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },
  },
  watch: {
    bAdminProvider() {
      return this.$store.state.bAdminProvider;
    },

  },
};
</script>

<style scoped>
.content-identification-information {
  margin-bottom: 25px;
  padding: 0px 0px 0px 12px;
}

.text-identification-information {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 16px;
  letter-spacing: 3px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.content-btns-accions {
  display: flex;
  padding: 0px 0px 0px 12px;
  margin-top: 25px;
  margin-bottom: 5px;
  width: 100% !important;
}

.content-btn-primary {
  width: 200px;
}

.content-btn-second {
  width: 200px;
  margin-right: 15px;
}

.text-identification {
  margin-bottom: 0px;
  color: var(--primary-color-text);
}

/*#region MODO RESPONSIVO */
/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-btns-accions {
    display: block;
    width: 100% !important;
  }

  .content-btn-second {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .content-btn-primary {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */
/*#endregion MODO RESPONSIVO */
</style>
